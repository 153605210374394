import { Benefits } from '#app/components/marketing/benefits.js'
import { Deliverable } from '#app/components/marketing/deliverable.js'
import { Features } from '#app/components/marketing/features.js'
import { Hero } from '#app/components/marketing/hero.tsx'
import { Top } from '#app/components/marketing/top.js'
import { WhoItsFor } from '#app/components/marketing/who-its-for.js'
import { bbai } from '#app/utils/bbai/bbai.js'
import { type MetaFunction } from '@remix-run/node'
import NewsletterSignup from '../resources+/newsletter-signup'

export const meta: MetaFunction = () => [{ title: 'BrainBuild' }]

// export async function loader({ request }: LoaderFunctionArgs) {
// 	// const publicTenantId = (
// 	// 	await db.query.tenantSchema.findFirst({
// 	// 		where: eq(tenantSchema.name, 'Public Gallery'),
// 	// 		columns: { id: true },
// 	// 	})
// 	// )?.id
// 	// invariantResponse(publicTenantId, 'Public Gallery tenant not found')

// 	return json({ location: request.url })
// }

export default function Landing() {
	return (
		<>
			<Hero>
				<div className="text-3xl md:text-4xl font-bold">
					{bbai.marketing.hero.main}
				</div>
				<div className="text-muted-foreground md:text-xl lg:mt-3 mt-2">
					{bbai.marketing.hero.sub}
				</div>
				<Deliverable />
				<Top />
			</Hero>
			<Benefits />
			<WhoItsFor />
			{/* <StepsToYourApp /> */}
			<Features />
			{/* <Faqs /> */}
			<div className="mx-4 flex justify-center">
				<NewsletterSignup className="mt-6 sm:max-w-lg " />
			</div>
		</>
	)
}
